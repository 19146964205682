
.upload-container {
  height: 100%;
}

.errors {
  list-style: none;
  padding-left: 0;

  > li {
    border-radius: 5px;
    color: red;
  }
}

.specs {
  padding-left: 0;
  font-size: 12px;
  list-style: none;
}

.upload-container {
  text-align: center;
  align-content: center;
  align-items: center;
  flex-direction: column;
}

.Upload {
  background: #dcdcdc;

  padding: 30px;
  border: 1px solid #AAA;
  border-radius: 30px;

  margin-bottom: 15px;

  &.drag-over {
    background: #1e1e1e;
  }
}

.file-collection {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-content: center;
  flex-wrap: wrap;
}

.file-icon {
  $width: 150px;
  $height: 150px;
  width: $width;
  height: $height;
  margin: 10px;
  border-radius: 10px;
  position: relative;
  overflow: hidden;

  img {
    object-fit: cover;
    width: $width;
    height: $height;
  }
}

.upload-ok {
  box-sizing: border-box;
  border: 1px solid green;
}

.file-progress {
  transition: width 0.6s ease;
  position:  absolute;
  top: 0;
  right: 0;
  height: 100%;
  background: white;
  opacity: 0.7;
}

.progress {
  margin-bottom: 30px;
}

.remove-file-btn {
  background: none;
  position: absolute;
  right: 5px;
  top: 5px;
  z-index: 10;
  cursor: pointer;

  img {
    max-width: 20px;
    max-height: 20px;
  }
}

.size {
  font-size: 11px;
  background: none;
  position: absolute;
  right: 5px;
  bottom: 5px;
  z-index: 10;
  cursor: pointer;
}
