.form-container {
  text-align: left;
  margin: 0 auto;
}

ul.list-inline {
  > li {
    @extend .list-inline-item;
  }
}

.has-error .help-block, .form-error {
  color: red;
}

.form-error {
  margin-bottom: 15px;
}
